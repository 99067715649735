<template>
  <div class="ViewModule">
    <site-navbar />
    <div class="page-content" v-if="ismodule">
      <div
        class="jumbotron"
        :style="
          `background-image: url('/assets/images/cover/` +
          ismodule.modulePicture +
          `')`
        "
      >
        <div class="left">
          <div class="module-info">
            <h1>{{ $txt(ismodule.moduleName) }}</h1>
            <p>
              {{ $txt(ismodule.description) }}
            </p>
          </div>
          <div class="module-actions">
            <router-link
              :to="{ name: 'allSchools' }"
              class="btn"
            >
              {{ $txt("Manage My Schools") }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="products-widget">
        <ViewEducation />
      </div>
    </div>
  </div>
</template>

<script>
import ViewEducation from "@/views/site/ViewEducation.vue";
export default {
  name: "ViewModule",
  components: {
    ViewEducation,
  },
  data() {
    return {
      formStep: 0,
      ismodule: null,
      userLogged: false,
    };
  },
  methods: {
    getModule() {
      let moduleCode = this.$route.params.moduleCode;
      this.$startLoader();
      this.$store.dispatch("GET_MODULE_BY_CODE", "IEDU").then((res) => {
        this.ismodule = res.data.data.data[0];
        this.$stopLoader();
      });
    },
    viewDashboard(moduleCode) {
      if (moduleCode == "IEDU") {
        window.open(process.env.VUE_APP_EDUCATION_URL, "_blank");
      }
      if (moduleCode == "IRAB") {
        window.open(process.env.VUE_APP_AGRICULTURE_URL, "_blank");
      }
    },
    getStarted(moduleCode) {
      if (moduleCode == "IEDU") {
        this.$router.push({ name: "Login" });
      } else {
        return;
      }
    },
  },
  mounted() {
    this.getModule();
    if (
      !this.$getUser() ||
      !this.$getToken() ||
      this.$getUser() == "undefined" ||
      this.$getToken() == "undefined"
    ) {
      this.userLogged = false;
    } else {
      this.userLogged = true;
    }
  },
  // watch for route changes
  watch: {
    $route(to, from) {
      this.getModule();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.ViewModule {
  .page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    .jumbotron {
      width: 100%;
      height: fit-content;
      background-position: right;
      background-size: 25% 150%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      .left {
        width: 85%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #eee;
        padding: 1rem 5rem;
        button {
          display: inline-block !important;
        }
        // make it a trapazoid
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
        @media screen and (max-width: 768px) {
          clip-path: none;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 3rem 2rem;
        }

        .module-info {
          width: 70%;
          h1 {
            font-size: 3rem;
            font-weight: 700;
            margin-bottom: 20px;
          }
          p {
            font-size: 1.2rem;
            line-height: 1.5;
          }
        }
        .module-actions {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          margin-right: 4rem;
          button,
          a {
            padding: 1rem 2rem;
            border-radius: 5px;
            background: $blue;
            border: 1px solid $blue;
            font-size: 1.2rem;
            font-weight: 700;
            cursor: pointer;
            transition: all 0.3s ease;
            color: #fff;
            display: block;
            &:hover {
              background: darken($blue, 10%);
            }
          }
        }
      }
    }
  }
}
</style>