<template>
    <div class="container mx-auto p-4">
      <div class="flex mb-4">
        <div class="w-1/3 p-4">
          <div class="bg-white rounded-lg shadow-lg p-6">
            <img src="/assets/images/expenses-2.svg" alt="" />
            <h2 class="text-xl font-semibold mb-2">50,000 in school expenses</h2>
          </div>
        </div>
        <div class="w-1/3 p-4">
          <div class="bg-white rounded-lg shadow-lg p-6">
            <img src="/assets/images/students.svg" alt="" />
            <h2 class="text-xl font-semibold mb-2">240 students</h2>
          </div>
        </div>
        <div class="w-1/3 p-4">
          <div class="bg-white rounded-lg shadow-lg p-6">
            <img src="/assets/images/category.svg" alt="" />
            <h2 class="text-xl font-semibold mb-2">4 Departments</h2>
          </div>
        </div>
      </div>
  

      </div>
        </template>
    
    <script>
  export default {
    data() {
      return {

      };
    },
    methods: {

    },
    mounted() {
      }
    }
  
  </script>
    
    <style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  </style>