import { createRouter, createWebHistory } from 'vue-router'
import Register from '../views/site/Register.vue'
import Home from '../views/site/Home.vue'
import Login from '../views/site/Login.vue'
import AddSchool from '../views/Education/AddSchool.vue'
import AddExpenseComponent from '../views/Education/Expenses/AddExpenseComponent.vue'
import AddStaff from '../views/Education/staff/AddStaff.vue'
import AddProperty from '../views/Education/properties/AddProperty.vue'
import Education from '../views/Education/Education.vue'
import AddSupplier from '../views/Education/suppliers/AddSupplier.vue'
import EmailVerification from '../views/site/EmailVerification.vue'
import VerifyEmail from '../views/site/VerifyEmail.vue'
import UserProfile from '../views/site/users/UserProfile.vue'
import AllExpenses from '../views/Education/Expenses/AllExpenses.vue'
import allSchools from '../views/Education/allSchools.vue'
import EditSchool from '../views/Education/EditSchool.vue'
import SchoolProperties from '../views/Education/properties/SchoolProperties.vue'
import SchoolStaff from '../views/Education/staff/SchoolStaff.vue'
import SchoolProfile from '../views/Education/SchoolProfile.vue'
import AllSuppliers from '../views/Education/suppliers/AllSuppliers.vue'
import staffMemberProfile from '../views/Education/staff/staffMemberProfile.vue'
import Reports from '../views/Education/Reports.vue'
import EditProfile from '../views/site/users/EditProfile.vue'
// superAdmin
import ForgotPassword from '../views/site/Forgot.vue'
import ResetPassword from '../views/site/ResetPassword.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/user/email-verification',
    name: 'EmailVerification',
    component: EmailVerification
  },
  {
    path: '/user/verifyAccount/:token',
    name: 'VerifyEmail',
    component: VerifyEmail
  },

  {
    path: '/user/profile',
    name: "UserProfile",
    component: UserProfile
  },

  {
    path: '/user/edit-profile',
    name: "EditProfile",
    component: EditProfile
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/user/resetPassword/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/education',
    name: 'Education',
    component: Education,
    children: [
      {
        path: '/add-school',
        name: 'AddSchool',
        component: AddSchool
      },
      {
        path: '/add-supplier',
        name: "AddSupplier",
        component: AddSupplier
      },
      {
        path: '/all-suppliers',
        name: 'AllSuppliers',
        component: AllSuppliers
      },
      {
        path: '/add-staffMember',
        name: 'AddStaffMember',
        component: AddStaff
      },
      {
        path: '/staff-members',
        name: 'StaffMembers',
        component: SchoolStaff
      },
      {
        path: '/add-property',
        name: 'AddProperty',
        component: AddProperty
      },
      {
        path: '/properties',
        name: 'AllProperties',
        component: SchoolProperties
      },
      {
        path: '/add-expense',
        name: 'AddExpense',
        component: AddExpenseComponent
      },
      {
        path: '/expenses',
        name: 'AllExpenses',
        component: AllExpenses
      },
      {
        path: '/reports',
        name: 'Reports',
        component: Reports
      },
      {
        path: '/school-profile',
        name: 'SchoolProfile',
        component: SchoolProfile
      },
      {
        path: '/staff-profile/:id',
        name: 'StaffProfile',
        component: staffMemberProfile
      },
      {
        path: '/school-profile/:id',
        name: 'SchoolProfile',
        component: SchoolProfile
      },
      {
        path: '/all-schools',
        name: 'allSchools',
        component: allSchools
      },
      {
        path: '/edit-school/:id',
        name: 'EditSchool',
        component: EditSchool
      },
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
  // {
  //   path: '/:catchAll(.*)',
  //   redirect: '/'
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
