<template>
  <div class="EditSchool">
    <div class="page-content">
      <!-- username/email, password -->
      <form action="" @submit.prevent="AddSchool()">
        <div class="form-header">
          <label for="" class="main-head">{{ $txt("Edit a school") }}</label>
          <!-- progress bar from 1 to 2 -->
          <div class="progress-bar">
            <span class="step" :class="{ active: formStep >= 0 }"></span>
            <span class="step" :class="{ active: formStep == 1 }"></span>
          </div>
        </div>
        <!--formStep Telephone, Password -->
        <div class="form-body" v-show="formStep == 0">
          <div class="form-group group-2">
            <input
              type="text"
              name="SchoolName"
              :placeholder="$txt('School Name')"
              v-model="school.school_name"
            />
            <select name="category" id="" v-model="school.category">
              <option value="">{{ $txt("Category") }}</option>
              <option>{{ $txt("Public") }}</option>
              <option>{{ $txt("Private") }}</option>
              <option>{{ $txt("Government Aided") }}</option>
            </select>
          </div>
          <div class="form-group group-3">
            <!-- email, phone, website -->
            <input
              type="text"
              name="email"
              :placeholder="$txt('Email')"
              v-model="school.email"
            />
            <input
              type="text"
              name="telephone"
              :placeholder="$txt('Telephone')"
              v-model="school.telephone"
            />
            <input
              type="text"
              name="website"
              :placeholder="$txt('Website')"
              v-model="school.website"
            />
          </div>
          <div class="form-group group-4">
            <!-- Province, Disctict, sector, cell -->
            <select
              name="province"
              id=""
              v-model="school.province"
              @change="getDistricts(school.province)"
            >
              <option value="">{{ $txt("Province") }}</option>
              <option
                v-for="province in provinces"
                :key="province.provinceId"
                :value="province.provinceId"
              >
                {{ province.provinceName }}
              </option>
            </select>
            <select
              name="district"
              id=""
              v-model="school.district"
              @change="getSectors(school.district)"
            >
              <option value="">{{ $txt("District") }}</option>
              <option
                v-for="district in districts"
                :key="district.districtId"
                :value="district.districtId"
              >
                {{ district.districtName }}
              </option>
            </select>
            <select
              name="sector"
              id=""
              v-model="school.sector"
              @change="getCells(school.sector)"
            >
              <option value="">{{ $txt("Sector") }}</option>
              <option
                v-for="sector in sectors"
                :key="sector.sectorId"
                :value="sector.sectorId"
              >
                {{ sector.sectorName }}
              </option>
            </select>
            <select name="cell" id="" v-model="school.cell_id">
              <option value="">{{ $txt("Cell") }}</option>
              <option
                v-for="cell in cells"
                :key="cell.cellId"
                :value="cell.cellId"
              >
                {{ cell.cellName }}
              </option>
            </select>
          </div>
          <div class="form-group group-2">
            <VueMultiselect
              placeholder="Select level"
              v-model="school.levels"
              :options="levels"
              :multiple="true"
              :close-on-select="true"
              :hide-selected="true"
              label="levelName"
              track-by="levelId"
            >
            </VueMultiselect>
            <select name="ownership" id="" v-model="school.ownership">
              <option value="">{{ $txt("Ownership") }}</option>
              <option>{{ $txt("Individual") }}</option>
              <option>{{ $txt("Company") }}</option>
              <option>{{ $txt("Cooperative") }}</option>
              <option>{{ $txt("Church") }}</option>
              <option>{{ $txt("Government") }}</option>
            </select>
          </div>
          <!-- Gender, Special schools -->
          <div class="form-group group-2">
            <select name="gender" id="" v-model="school.gender">
              <option value="">{{ $txt("Gender") }}</option>
              <option>{{ $txt("Girls Only") }}</option>
              <option>{{ $txt("Boys Only") }}</option>
              <option>{{ $txt("Mixed") }}</option>
            </select>
            <select name="type" id="" v-model="school.education_type">
              <option value="">{{ $txt("Domain of education") }}</option>
              <option
                v-for="domain in domains"
                :key="domain.domainId"
                :value="domain.domainId"
              >
                {{ domain.domainName }}
              </option>
            </select>
          </div>
          <!-- <div class="form-group group-2">
            <select name="gender" id="" v-model="school.school_level">
              <option value="">{{ $txt("Main Level of Education") }}</option>
              <option v-for="level in levels" :key="level.levelId">
                {{ level.levelName }}
              </option>
            </select>
          </div> -->
          <div class="form-group">
            <textarea
              name="description"
              id=""
              rows="4"
              :placeholder="$txt('Description')"
              v-model="school.description"
            ></textarea>
          </div>

          <div class="form-group">
            <button type="submit" class="submit" @click.prevent="goNext(1)">
              {{ $txt("Next") }}
            </button>
          </div>
        </div>
        <div class="form-body2" v-show="formStep == 1">
          <div class="form-group group-4">
            <div class="input-group">
              <label for="">{{ $txt("Area size") }}</label>
              <input
                type="text"
                name="area"
                id=""
                v-model="school.properties.area"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Total classes") }}</label>
              <input
                type="number"
                name="totalClasses"
                id=""
                v-model="school.properties.totalClasses"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Total Female students") }}</label>
              <input
                type="number"
                name="totalmaleStudents"
                id=""
                v-model="school.properties.femaleStudents"
                @change="calculateTotal()"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Total Male students") }}</label>
              <input
                type="number"
                name="totalmaleStudents"
                id=""
                v-model="school.properties.maleStudents"
                @change="calculateTotal()"
              />
            </div>
          </div>
          <div class="form-group group-4">
            <div class="input-group">
              <label for="">{{ $txt("Total students") }}</label>
              <input
                type="number"
                name="totalStudents"
                disabled
                id=""
                v-model="school.properties.totalStudents"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Agricultural land") }}</label>
              <input
                type="text"
                name="agriculturalLand"
                id=""
                v-model="school.properties.agriculturalLand"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Total teachers") }}</label>
              <input
                type="number"
                name="totalTeachers"
                id=""
                v-model="school.properties.totalTeachers"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Total desktops") }}</label>
              <input
                type="number"
                name="totalDesktops"
                id=""
                v-model="school.properties.totalDesktops"
              />
            </div>
          </div>
          <div class="form-group group-4">
            <div class="input-group">
              <label for="">{{ $txt("Total laptops") }}</label>
              <input
                type="number"
                name="totalLaptops"
                id=""
                v-model="school.properties.totalLaptops"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Total printers") }}</label>
              <input
                type="number"
                name="totalPrinters"
                id=""
                v-model="school.properties.totalPrinters"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Flat screens") }}</label>
              <input
                type="number"
                name="flatScreens"
                id=""
                v-model="school.properties.flatScreens"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Speakers") }}</label>
              <input
                type="number"
                name="speakers"
                id=""
                v-model="school.properties.speakers"
              />
            </div>
          </div>
          <div class="form-group group-4">
            <div class="input-group">
              <label for="">{{ $txt("Microphones") }}</label>
              <input
                type="number"
                name="microphones"
                id=""
                v-model="school.properties.microphones"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Mixers") }}</label>
              <input
                type="number"
                name="mixers"
                id=""
                v-model="school.properties.mixers"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Water filters") }}</label>
              <input
                type="number"
                name="waterFilters"
                id=""
                v-model="school.properties.waterFilters"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Fire extinguishers") }}</label>
              <input
                type="number"
                name="fireExtinguishers"
                id=""
                v-model="school.properties.fireExtinguishers"
              />
            </div>
          </div>
          <div class="form-group group-4">
            <div class="input-group">
              <label for="">{{ $txt("Dustbins") }}</label>
              <input
                type="number"
                name="dustbins"
                id=""
                v-model="school.properties.dustbins"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Cars") }}</label>
              <input
                type="number"
                name="cars"
                id=""
                v-model="school.properties.cars"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Motocycles") }}</label>
              <input
                type="number"
                name="motocycles"
                id=""
                v-model="school.properties.motocycle"
              />
            </div>
            <div class="input-group">
              <label for="">{{ $txt("Bicycles") }}</label>
              <input
                type="number"
                name="bicycles"
                id=""
                v-model="school.properties.bicycles"
              />
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group radio">
              <label for="">{{ $txt("Water connector") }}</label>
              <input
                type="checkbox"
                name="waterConnector"
                id=""
                v-model="school.properties.waterConnector"
              />
            </div>
            <div class="input-group radio">
              <label for="">{{ $txt("Electricity connector") }}</label>
              <input
                type="checkbox"
                name="electricityConnector"
                id=""
                v-model="school.properties.electricityConnector"
              />
            </div>
            <div class="input-group radio">
              <label for="">{{ $txt("Solar system") }}</label>
              <input
                type="checkbox"
                name="solarSystem"
                id=""
                v-model="school.properties.solarSystem"
              />
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group radio">
              <label for="">{{ $txt("Has internet") }}</label>
              <input
                type="checkbox"
                name="hasInternet"
                id=""
                v-model="school.properties.hasInternet"
              />
            </div>
            <div class="input-group radio">
              <label for="">{{ $txt("Lightning Protector") }}</label>
              <input
                type="checkbox"
                name="hasInternet"
                id=""
                v-model="school.properties.lightningProtector"
              />
            </div>
            <div class="input-group radio">
              <label for="">{{ $txt("Fire insurance") }}</label>
              <input
                type="checkbox"
                name="hasInternet"
                id=""
                v-model="school.properties.fireInsurance"
              />
            </div>
          </div>
          <div class="form-group">
            <button type="submit" class="prev" @click.prevent="goNext(-1)">
              {{ $txt("Previous") }}
            </button>
            <button type="submit" class="submit">{{ $txt("Submit") }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      formStep: 0,
      value: null,
      specialvalue: null,
      levels: [],
      domains: [],
      degrees: [],
      school: {
        school_name: "",
        email: "",
        telephone: "",
        website: "",
        province: "",
        district: "",
        sector: "",
        cell_id: "",
        education_type: "",
        ownership: "",
        category: "",
        levels: [],
        gender: "",
        description: "",
        properties: {
          area: "",
          totalClasses: 0,
          totalStudents: 0,
          maleStudents: 0,
          femaleStudents: 0,
          agriculturalLand: "",
          totalTeachers: 0,
          totalDesktops: 0,
          totalLaptops: 0,
          totalPrinters: 0,
          hasInternet: false,
          flatScreens: 0,
          speakers: 0,
          microphones: 0,
          mixers: 0,
          waterFilters: 0,
          fireExtinguishers: 0,
          Dustbins: 0,
          cars: 0,
          motocycle: 0,
          bicycles: 0,
          waterConnector: true,
          electricityConnector: true,
          solarSystem: true,
          lightningProtector: true,
          fireInsurance: true,
        },
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
    };
  },
  methods: {
    goNext(num) {
      this.formStep += num;
    },
  },
  mounted() {
    if (
      this.$store.state.schoolToEdit &&
      this.$store.state.schoolToEdit != null &&
      this.$store.state.schoolToEdit != undefined
    ) {
      this.school = this.$store.state.schoolToEdit;
      this.school.properties = this.school.schoolProperties;
    }
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.EditSchool {
  .page-content {
    width: 100%;
    display: flex;
    justify-content: center;
    form {
      width: 100%;
      max-width: 70%;
      min-height: 500px;
      padding: 2rem 5rem;
      border-radius: 5px;
      // box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      position: relative;

      .form-header {
        margin-bottom: 20px;
        .progress-bar {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 2rem;
          .step {
            width: 100px;
            height: 10px;
            border-radius: 5px;
            background: #ddd;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
            font-weight: 600;
            color: #fff;
            &.active {
              background: $blue;
            }
          }
          .progress {
            width: 100%;
            background: #ddd;
            .bar {
              width: 0%;
              height: 5px;
              background: #ddd;
              position: relative;
              background: $blue;
              &.active {
                width: 100%;
                transition: all 0.1s ease-in-out;
              }
            }
          }
        }
        .main-head {
          display: block;
          text-align: center;
          font-size: 20px;
          margin-bottom: 10px;
          span {
            color: $blue;
            font-weight: 600;
          }
        }
        .dont {
          display: block;
          text-align: center;
          span,
          a {
            color: $blue;
            font-weight: 600;
          }
        }
      }
      .form-body {
        .form-group {
          margin-bottom: 20px;
          position: relative;
          label {
            display: block;
            margin-bottom: 5px;
            position: absolute;
            top: 5px;
            left: 10px;
            font-size: 13px;
          }
          input,
          textarea,
          select {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
          }
        }
        .group-2 {
          display: flex;
          column-gap: 2rem;
        }
        .group-3 {
          display: flex;
          column-gap: 2rem;
        }
        .group-4 {
          display: flex;
          column-gap: 2rem;
        }
      }
      .form-body2 {
        .form-group {
          display: grid;
          column-gap: 1rem;
          &.group-4 {
            grid-template-columns: repeat(4, 1fr);
          }
          &.group-3 {
            grid-template-columns: repeat(3, 1fr);
          }
          .input-group {
            display: flex;
            flex-direction: column;
            label {
              margin-bottom: 5px;
            }
            input {
              margin-bottom: 10px;
              border: 1px solid #ddd;
              border-radius: 5px;
              padding: 10px;
              outline: none;
            }
            &.radio {
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              align-items: center;
              margin: 1rem 0;
              input {
                // make it bigger and smarter
                width: 15px;
                height: 15px;
                margin-left: 10px;
                margin-bottom: 0;
              }
              label {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .submit {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        outline: none;
        background: $blue;
        color: #fff;
        font-weight: 600;
      }
      .prev {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        outline: none;
        background: #ddd;
        color: #000;
        font-weight: 600;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      .form-footer {
        position: absolute;
        bottom: 20px;
        width: calc(100% - 10rem);
        h4 {
          text-align: center;
        }
      }
    }
  }
}
</style>