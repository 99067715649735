<template>
    <div class="container mx-auto p-4">


      <h2 class="text-xl font-semibold mb-4">Recent expenses</h2>
      <div class="flex justify-between mb-4">

           <button class="add-btn" @click="openPopup">Add Property</button>

           <model
        :modelTitle="'Add Property'"
        :largeModel="false"
        v-show="popupOpen"
        @closeModel="closePopup"
      >
        <AddProperty @closeModel="closePopup"/>
      </model>
              <select name="" id="" v-model="activeSchool" @change="getProperties()" placeholder="choose school">
                <option value="">Select school</option>
                <option :value="school.id" v-for="school in schools" :key="school.id">{{ school.schoolName }}</option>
              </select>
            
      </div>

        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Property Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Property Size
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Property Description
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Property Value
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Property Count
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Property Status
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>

          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(item, index) in properties" :key="index" :class="{ 'bg-purple-100': index % 2 === 0 }">
              <td class="px-6 py-4 whitespace-nowrap">{{ item.propertyName }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.sizeInHa }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.description }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.amountInPrice }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.numberofPropertyItems }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.status }}</td>
              <td class="flex items-center mart">
                <a href="#"><img src="/assets/images/delete.svg" alt="" /></a>
                <a href="#"><img src="/assets/images/edit.svg" alt="" /></a>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
  </template>
  
  <script>
import PopUpComponent from '@/views/components/PopUpComponent.vue';
import AddProperty from './AddProperty.vue';

  export default {
    data() {
        return {

            popupOpen: false,
            schools:[],
            activeSchool: "",
            properties:[],
        };
    },
    
    components:  {AddProperty,PopUpComponent },
    methods: {
    openPopup() {
      this.popupOpen = true;
    },
    closePopup(){
      this.popupOpen = false;
    },

    getSchools() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SCHOOLS", { token: this.$getToken() })
        .then((res) => {
          this.schools = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.res.status == 401) {
            this.$userLogout();
          }
        });
    },
    getProperties() {
      const schoolId = this.activeSchool;
      this.$startLoader();
      this.$store
        .dispatch("GET_SCHOOL_PROPERTIES", { 
          token: this.$getToken() ,
          school_id:schoolId,
        })
        .then((res) => {
          console.log(res)
          this.properties = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$userLogout();
          }
        });
    },

  },
  mounted(){
      this.getSchools();
  }
}
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/scss/main.scss";

            .plan{
            width: 120px;
            padding: 10px;
            margin-left: 500px;
            border: 1px solid $blue;
            border-radius: 5px;
            outline: none;
            background: white;
            color: $blue;
            font-weight: 600;
            }

            .mart{
                margin-top: 20px;
                margin-left: 40px;
                padding-right: 20px;
            }

            .add-btn{
            width: 160px;
            padding: 10px;
            border: 1px solid;
            border-radius: 5px;
            outline: none;
            background: $blue;
            color: white;
            font-weight: 600;
            }

  </style>
  