<template>

    <div>
      <div class="page-content">
        <form action="">
          <div class="form-header">
            <label for="" class="text-grey-200 font-semibold"
              >{{ $txt("Add an Expense") }} </label
            >
          </div>
          <div class="form-body">

            <div class="form-group">
              <input
                type="text"
                name="item_name"
                :placeholder="$txt('Expense Name')"
              />
            </div>
            <div class="form-group">
                <div class="mb-4">

                    Select Category
          <select
            v-model="selectedCategory"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="category"
          >
            <option value="Feeding">Feeding</option>
            <option value="Utilities">Utilities</option>
            <option value="Infrastructure">Infrastructure</option>
            <option value="Human Resource">Human Resource</option>
            <option value="Miscellaneous">Miscellaneous</option>

          </select>
        </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                name="amount"
                :placeholder="$txt('Enter the amount')"
              />
            </div>
            
            <div class="mb-4 flex flex-wrap">
          <div class="w-1/2 pr-2">
       
            <label className="text-gray">Academic year</label>
            <div class="form-group">
              <input
                type="date"
                name="academic_year"
              />
            </div>
          </div>
          <div class="w-1/2 pl-2">
     
            <label className="text-gray">Expense purchase date</label>
            <div class="form-group">
              <input
                type="date"
                name="date"
              />
            </div>
          </div>
        </div>



            <div class="mb-4 flex flex-wrap">
          <div class="w-1/2 pr-2">
            <input
              class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="quantity"
              type="text"
              placeholder="Expense quantity"
            />
          </div>
          <div class="w-1/2 pl-2">
            <input
              class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="names"
              type="text"
              placeholder="Unit price"
            />
          </div>
        </div>

            <div class="form-group">
              <input
                type="text"
                name="supplier"
                :placeholder="$txt('Expense supplier')"
              />
            </div>

            <div class="form-group">
              <input
                type="text"
                name="payment_method"
                :placeholder="$txt('Payment method')"
              />
            </div>

            <div class="form-group">
              <input
                type="text"
                name="description"
                :placeholder="$txt('Description')"
              />
            </div>
            <div class="form-group">
              <button type="submit" class="submit">{{ $txt("Create Expense") }}</button>
              
            </div>
          </div>
         
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
    return {
      showPopup: false,
    };
  },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

.page-content {
  width: 100%;
  display: flex;
  justify-content: center;

  form {
    width: 100%;
    max-width: 600px;
    height: auto;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 1.5em;
      cursor: pointer;
    }

    .form-header {
      margin-bottom: 20px;

      .main-head {
        text-align: center;
        font-size: 20px;
        margin-bottom: 5px;
        margin-top: 20px;

        span {
          color: $blue;
          font-weight: 600;
        }
      }
    }

    .form-body {
      .form-group {
        margin-bottom: 20px;
        position: relative;

        label {
          display: block;
          margin-bottom: 5px;
          position: absolute;
          top: 5px;
          left: 10px;
          font-size: 13px;
        }

        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          outline: none;
        }

        .submit {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          outline: none;
          background: $blue;
          color: #fff;
          font-weight: 600;
          cursor: pointer;

          &:hover {
            background: darken($blue, 10%);
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-content {
      padding: 1rem;
    }

    form {
      padding: 1rem;
    }
  }
}
  </style>