<template>
  <div class="allSchools">
    <div class="header">
      <div class="left">
        <h1>{{ $txt("All Schools") }}</h1>
        <p>
          {{
            $txt(
              "You can click and manage any of your schools or add a new school."
            )
          }}
        </p>
      </div>
      <div class="right">
        <router-link :to="{ name: 'AddSchool' }">
          <button class="btn btn-primary">
            {{ $txt("Add New School") }}
          </button>
        </router-link>
      </div>
    </div>
    <div class="page-body">
      <div class="filter-schools">
        <div class="filter-left"></div>
        <div class="filter-right">
          <form class="search">
            <input type="text" :placeholder="$txt('Search')" />
            <i class="fa fa-search"></i>
          </form>
        </div>
      </div>
      <div class="schools-list">
        <div class="school-item" v-for="school in schools" :key="school.id">

          <div class="school-item-right">
            <div class="left">
              <h3 class="school-name">{{ school.schoolName }}</h3>
              <p>{{ school.schoolEmail }}</p>
              <p>{{ school.schoolTelephone }}</p>
              <p>{{ school.schoolWebsite }}</p>
              <p>
                <span v-for="level in school.schoollevels" :key="level.levelId"
                  >{{ level.levelName }},
                </span>
              </p>
            </div>
            <div class="director">
              <p>Director/Principal/HM:</p>
              <label>ANET MUTAMULIZA</label>
              <p class="message">
               Lorem ipsum for your good
              </p>
              <router-link
                :to="{ name: 'SchoolProfile', params: { id: school.id } }"
                >View School</router-link
              >
              <button @click="editSchool(school)">Edit School</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "allSchools",
  data() {
    return {
      schools: [],
    };
  },
  methods: {
    getSchools() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SCHOOLS", { token: this.$getToken() })
        .then((res) => {
          this.schools = res.data.data.data;
          this.$store.state.schools = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$userLogout();
          }
        });
    },
    editSchool(school) {
      this.$store.state.schoolToEdit = school;
      this.$router.push({ name: "EditSchool", params: { id: school.id } });
    },
  },
  mounted() {
    this.getSchools();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.allSchools {
  padding: 2rem 4rem;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .left {
      h1 {
        font-size: 24px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #5a5a5a;
        font-style: italic;
      }
    }
    .right {
      button {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        background-color: $blue;
        border-radius: 5px;
        padding: 10px 20px;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          background-color: #333333;
        }
      }
    }
  }
  .page-body {
    margin-top: 4rem;
    .filter-schools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filter-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: fit-content;
        .most-liked {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          input {
            margin-right: 10px;
            font-size: 12px;
          }
          label {
            display: block;
            font-size: 12px;
          }
        }
        select {
          height: 40px;
          border: none;
          border-bottom: 1px solid #ccc;
          padding: 0 10px;
          font-size: 12px;
          margin-left: 10px;
          width: 100%; /* Full width on small screens */
          margin-top: 0.5rem; /* Add some space */
        }
      }
      .filter-right {
        width: 300px;
        .search {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          position: relative;
          input {
            width: 100%;
            height: 40px;
            border-radius: 5px;
            border: 1px solid #ccc;
            padding: 0 10px;
            padding-right: 40px;
          }
          i {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute !important;
            right: 10px;
          }
        }
      }
    }
    .schools-list {
      margin-top: 2rem;
      .school-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 2rem;
        border-bottom: 1px solid #ccc;
        .school-item-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            width: 60%;
            .school-name {
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 10px;
              color: $blue;
            }
            p {
              font-size: 13px;
              margin-bottom: 5px;
            }
          }
          .director {
            width: 40%;
            p {
              font-size: 13px;
              margin-bottom: 5px;
            }
            label {
              font-size: 13px;
              margin-bottom: 5px;
              font-weight: bold;
              color: $blue;
            }
            .message {
              font-size: 12px;
              margin-bottom: 5px;
              color: #1f1f1f;
            }
            a, button {
              font-size: 14px;
              margin-bottom: 5px;
              background: $blue;
              color: #fff;
              padding: 7px 15px;
              cursor: pointer;
              border-radius: 2px;
              margin-right: 10px;
              margin-top: 10px;
              display: inline-block;
              width: 150px;
              text-align: center;
              &:hover {
                background: darken($blue, 10%);
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 640px) {
  .allSchools {
    .header {
      flex-direction: row; 
      justify-content: space-between;
      .left {
        margin-bottom: 0;
      }
    }

    .page-body {
      .filter-schools {
        .filter-right {
          width: auto; 
        }
      }

      .schools-list {
        .school-item {
          .school-item-left {
            width: 30%; 
          }

          .school-item-right {
            width: 70%; 
          }
        }
      }
    }
  }
}
}
</style>