<template>
    <div class="container mx-auto p-4">
      <div class="flex mb-4">
        <div class="w-1/3 p-4">
          <div class="bg-white rounded-lg shadow-lg p-6">
            <img src="/assets/images/solar.svg" alt="" />
            <h2 class="text-xl font-semibold mb-2">50,000 in savings</h2>
          </div>
        </div>
        <div class="w-1/3 p-4">
          <div class="bg-white rounded-lg shadow-lg p-6">
            <img src="/assets/images/expenses-2.svg" alt="" />
            <h2 class="text-xl font-semibold mb-2">12 Total Expenses</h2>
          </div>
        </div>
        <div class="w-1/3 p-4">
          <div class="bg-white rounded-lg shadow-lg p-6">
            <img src="/assets/images/category.svg" alt="" />
            <h2 class="text-xl font-semibold mb-2">4 Categories</h2>
          </div>
        </div>
      </div>
  

      <h2 class="text-xl font-semibold mb-4">Recent expenses</h2>
      <div class="flex justify-between mb-4">

            <button class="plan">Make a plan</button>
           <button class="add-btn" @click="openPopup">Add Expenses</button>
           <model
        :modelTitle="'Add Expense'"
        :largeModel="true"
        v-show="popupOpen"
        @closeModel="closePopup"
      >
        <AddExpenseComponent @closeModel="closePopup" />
      </model>
            
      </div>

        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Category
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>

          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(item, index) in tableData" :key="index" :class="{ 'bg-purple-100': index % 2 === 0 }">
              <td class="px-6 py-4 whitespace-nowrap">{{ item.category }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.name }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.amount }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.description }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ item.date }}</td>

              <td class="flex items-center space-x-2 mart">
                <a href="#"><img src="/assets/images/delete.svg" alt="" /></a>
                <a href="#"><img src="/assets/images/edit.svg" alt="" /></a>
                <a href="#"><img src="/assets/images/suppliers-2.svg" alt="" /></a>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
  </template>
  
  <script>
import PopUpComponent from '@/views/components/PopUpComponent.vue';
import AddExpenseComponent from './AddExpenseComponent.vue';

  export default {
    data() {
        return {
            tableData: [
                {
                    category: "Category 1",
                    name: "Item 1",
                    amount: 100,
                    description: "Description 1",
                    date: "2023-09-06",
                },
                {
                    category: "Category 2",
                    name: "Item 2",
                    amount: 200,
                    description: "Description 2",
                    date: "2023-09-07",
                },
                {
                    category: "Category 3",
                    name: "Item 3",
                    amount: 300,
                    description: "Description 3",
                    date: "2023-09-08",
                },
            ],
            popupOpen: false,
        };
    },
    
    components: { AddExpenseComponent, PopUpComponent },
    methods: {
    openPopup() {
      this.popupOpen = true;
    },
    closePopup(){
      this.popupOpen = false;
    }
  },
}
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/scss/main.scss";

            .plan{
            width: 120px;
            padding: 10px;
            margin-left: 500px;
            border: 1px solid $blue;
            border-radius: 5px;
            outline: none;
            background: white;
            color: $blue;
            font-weight: 600;
            }

            .mart{
                margin-top: 20px;
            }

            .add-btn{
            width: 160px;
            padding: 10px;
            border: 1px solid;
            border-radius: 5px;
            outline: none;
            background: $blue;
            color: white;
            font-weight: 600;
            }

  </style>
  