<template>
  <notifications position="bottom right" style="z-index: 99999999999;" />
  <iloader v-if="$store.state.isLoading" />
  <router-view />
</template>
<script>
import iloader from "./views/components/iloader.vue";
export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    iloader,
  },
  methods: {
    getModules() {
      this.$store.dispatch("GET_ALL_MODULES").then((res) => {
        this.$store.state.ismodules = res.data.data.data;
      });
    },
  },
  mounted() {
    this.$getCountries();
    this.getModules();
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";
.page-content {
  // margin-top: 10px;
}

.form-body {
  .form-group {
    margin-bottom: 20px;
    position: relative;
    label {
      display: block;
      margin-bottom: 5px;
      position: absolute;
      top: 5px;
      left: 10px;
      font-size: 13px;
      color: #5e5e5e;
    }
    input,
    select {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      outline: none;
    }
    .submit,
    .prev {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      outline: none;
      background: $blue;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        background: darken($blue, 10%);
      }
    }
    .prev {
      background: #000;
      &:hover {
        background: #000;
      }
    }
  }
}
.page-content {
  height: calc(100vh - 60px);
  overflow-y: auto;
}
</style>