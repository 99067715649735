<template>
    <div class="suppliers">
      <div class="page-content">
        <form action="">
          <div class="form-header">
            <label for="" class="main-head"
              >{{ $txt("Add school suppliers") }} </label
            >
          </div>
          <div class="form-body">

            <div class="form-group">
              <input
                type="text"
                name="supplier_name"
                :placeholder="$txt('Supplier name')"
              />
            </div>

            <div class="form-group">
              <input
                type="number"
                name="supplier_contact"
                :placeholder="$txt('Supplier contact')"
              />
            </div>

            <div class="form-group">
              <input
                type="text"
                name="supplier_acc_no"
                :placeholder="$txt('Supplier account')"
              />
            </div>
            
            <div class="form-group">
              <input
                type="text"
                name="description"
                :placeholder="$txt('Supplier Description')"
              />
            </div>
            <div class="form-group">
              <button type="submit" class="submit">{{ $txt("Add Item Supplier") }}</button>
              <button type="submit" class="submit-2">{{ $txt("View Existing") }}</button>

            </div>
          </div>
         
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/main.scss";
  .page-content {
    width: 100%;
    display: flex;
    justify-content: center;
    form {
      width: 100%;
      max-width: 600px;
      height: 500px;
      padding: 4rem 5rem;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      position: relative;
  
      .form-header {
        margin-bottom: 20px;
        .main-head {
          display: block;
          text-align: center;
          font-size: 20px;
          margin-bottom: 10px;
          span {
            color: $blue;
            font-weight: 600;
          }
        }
       
      }
      .form-body {
        .form-group {
          margin-bottom: 20px;
          position: relative;
          label {
            display: block;
            margin-bottom: 5px;
            position: absolute;
            top: 5px;
            left: 10px;
            font-size: 13px;
          }
          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
          }
          .submit {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
            background: $blue;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              background: darken($blue, 10%);
            }
          }

          .submit-2 {
            width: 100%;
            padding: 10px;
            border: 1px solid #3B4874;
            border-radius: 5px;
            outline: none;
            background: #fff;
            color: #3B4874;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              background: darken($blue, 10%);
              color:#fff;
            }
          }
        }
      }

    }
  }
  </style>