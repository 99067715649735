import { API_URL } from '../variables';
import axios from 'axios'
const GET_ALL_USERS_URL = API_URL + '/users/all';
const DELETE_USER_URL = API_URL + '/users';
const GET_USER_ROLES_URL = API_URL + '/roles/all';
const CHANGE_USER_ROLE_URL = API_URL + '/roles/change';
const ASSIGN_MODULE_ADMIN_URL = API_URL + '/modules/admin';
const REMOVE_MODULE_ADMIN_URL = API_URL + '/modules/admin/remove';
const GET_MODULE_ADMINS_URL = API_URL + '/modules/admin/all';
const GET_USERS_BY_ADMINS_URL = API_URL + '/modules/byAdmins';
const users = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_ALL_USERS']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_USERS_URL,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_USER']: ({ commit }, payload) => {
            return axios({
                url: DELETE_USER_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_USER_ROLES']: ({ commit }, payload) => {
            return axios({
                url: GET_USER_ROLES_URL,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['CHANGE_USER_ROLE']: ({ commit }, payload) => {
            return axios({
                url: CHANGE_USER_ROLE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['ASSIGN_MODULE_ADMIN']: ({ commit }, payload) => {
            return axios({
                url: ASSIGN_MODULE_ADMIN_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['REMOVE_MODULE_ADMIN']: ({ commit }, payload) => {
            return axios({
                url: REMOVE_MODULE_ADMIN_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_MODULE_ADMINS']: ({ commit }, payload) => {
            return axios({
                url: GET_MODULE_ADMINS_URL,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_USERS_BY_ADMINS']: ({ commit }, payload) => {
            return axios({
                url: GET_USERS_BY_ADMINS_URL,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        }
    },
    modules: {},
}
export default users;