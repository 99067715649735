import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const USER_REGISTER_URL = API_URL + '/auth/register';
const VERIFY_EMAIL_URL = API_URL + '/auth/account/verify';
const LOGIN_USER_URL = API_URL + '/auth/login';
const LOGOUT_USER_URL = API_URL + '/auth/logout';
const SEND_RESET_LINK_URL = API_URL + '/password/reset';
const RESET_PASSWORD_URL = API_URL + '/password/change';

const translation = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['USER_REGISTER']: ({ commit }, payload) => {
            return axios({
                url: USER_REGISTER_URL,
                method: "POST",
                data: payload
            })
        },
        ['VERIFY_EMAIL']: ({ commit }, payload) => {
            return axios({
                url: VERIFY_EMAIL_URL,
                method: "POST",
                data: payload
            })
        },
        ['LOGIN_USER']: ({ commit }, payload) => {
            return axios({
                url: LOGIN_USER_URL,
                method: "POST",
                data: payload
            })
        },
        ['LOGOUT_USER']: ({ commit }, payload) => {
            return axios({
                url: LOGOUT_USER_URL,
                method: "POST",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['SEND_RESET_LINK']: ({ commit }, payload) => {
            return axios({
                url: SEND_RESET_LINK_URL,
                method: "POST",
                data: payload
            })
        },
        ['RESET_PASSWORD']: ({ commit }, payload) => {
            return axios({
                url: RESET_PASSWORD_URL,
                method: "POST",
                data: payload
            })
        }
    },
    modules: {},
}
export default translation;