import { API_URL } from '../variables';
import axios from 'axios'
const GET_DOMAINS_URL = API_URL + '/schools/domains';
const ADD_DOMAIN_URL = API_URL + '/domains/add-domain';
const EDIT_DOMAIN_URL = API_URL + '/domains/edit-domain';
const DELETE_DOMAIN_URL = API_URL + '/domains/delete-domain';


const domains = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_DOMAINS']: ({ commit }, payload) => {
            return axios({
                url: GET_DOMAINS_URL,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['ADD_DOMAIN']: ({ commit }, payload) => {
            return axios({
                url: ADD_DOMAIN_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['EDIT_DOMAIN']: ({ commit }, payload) => {
            return axios({
                url: EDIT_DOMAIN_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_DOMAIN']: ({ commit }, payload) => {
            return axios({
                url: DELETE_DOMAIN_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        }

    },
    modules: {},
}
export default domains;