<template>
  <div v-if="staffs.userName || staffs.highestDegree" class="staff-info">

        <div class="general">
            <h1 class="text-blue-500 underline font-bold">General</h1>
            <p><span class="font-bold">Names :  </span>{{ staffs.userName.names }}</p>
            <p><span class="font-bold">Gender :  </span>{{ staffs.userName.gender }}</p>
            <p><span class="font-bold">Degree :  </span> {{ staffs.highestDegree.degreeName }}</p>
            <p><span class="font-bold">Department :  </span> {{ staffs.departement.name }}</p>
            <p><span class="font-bold">Domain :  </span>{{ staffs.educationDomain }}</p>      
            <button type="submit" class="submit">{{ $txt("Edit General Info") }}</button>
      
          
        </div>

        <div class="contacts">
            <p class="text-blue-500 underline font-bold">Contact Info</p>
            <p><span class="font-bold">Email :  </span>{{ staffs.userName.emailAddress }}</p>
            <p><span class="font-bold">Telephone :  </span>{{ staffs.userName.telephone }}</p>
            <p><span class="font-bold">Passport/ID :  </span>{{ staffs.userName.id_passport_number }}</p>
            <p><span class="font-bold">Nationality :  </span>{{ staffs.userName.nationalityName.countryName }}</p> 
            <button type="submit" class="submit">{{ $txt("Edit Contacts & Address") }}</button>
 

        </div> 

    </div>
</template>


  
  <script>
import staff from '@/store/modules/staff';

  export default {
    data() {
      return {
        staffs:[],
        
      };
    },
    methods: {
      getStaffByID() {
        const staffId = this.$route.params.id;
        this.$startLoader();
        this.$store
          .dispatch("GET_STAFF_BY_ID", {
            token: this.$getToken(),
            staffId,
          })
          .then((response) => {
            console.log(response);
            this.staffs = response.data.data.data;  
            console.log(this.staffs.schoolEmail)
            this.$stopLoader();
          })
          .catch((err) => {
            console.error(err);
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.error,
            });
            this.$stopLoader();
          });
      },
    },
    mounted() {
      this.getStaffByID();
    },
  };
  </script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.staff-info {
  display: flex;
  margin: 0 -10px; 
}

.general,
.contacts {
  flex: 1;
  padding: 10px;
  margin-top: 40px; 
  background-color: #fff;
  padding: 5%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit {
  margin-top: 40px;
  width: 200px;
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  outline: none;
  background: $blue;
  color: white;
  font-weight: 600;
}
.general{
  margin-left: 80px;
}

.contacts{
  margin-right: 50px;
  margin-left: 120px;
}
</style>
