<template>
    <div class="properties">
      <div class="page-content">
        <form action="" @submit.prevent="addProperty()">
          <div class="form-header">
            <label for="" class="main-head"
              >{{ $txt("Add school Properties") }} </label
            >
          </div>
          <div class="form-body">

            <div class="form-group">
              <select name="" id="" v-model="property.school">
                <option value="">Select school</option>
                <option :value="school.id" v-for="school in schools" :key="school.id">{{ school.schoolName }}</option>
              </select>
            </div>
          

            <div class="form-group">
              <select name="" id="" v-model="property.property">
                <option value="">Select Property</option>
                <option :value="property.checkId" v-for="property in properties" :key="properties.checkId">{{ property.checkName }}</option>
              </select>
            </div>

            <div class="form-group">
              <input
                type="number"
                name="number"
                :placeholder="$txt('Property number')"
                v-model="property.number"
              />
            </div>

            <div class="form-group">
              <input
                type="number"
                name="size"
                :placeholder="$txt('Property size')"
                v-model="property.size"
              />
            </div>

            <div class="form-group">
              <input
                type="text"
                name="amount"
                :placeholder="$txt('Property Value')"
                v-model="property.amount"
              />
            </div>
            
            <div class="form-group">
              <input
                type="text"
                name="description"
                :placeholder="$txt('Property Description')"
                v-model="property.description"
              />
            </div>
            <div class="form-group">
              <button type="submit" class="submit">{{ $txt("Add Property") }}</button>
              <button type="submit" class="submit-2">{{ $txt("View Existing") }}</button>

            </div>
          </div>
         
        </form>
      </div>
    </div>
  </template>
  
  <script>
    import axios from 'axios';
  export default {

    data() {
    return {
      property:{
        property:"",
        size:"",
        amount:"",
        number:"",
        description:"",
        status:true,
        school:"",

      },
      schools: [],
      properties:[]
    };
  },

  methods:{
    addProperty() {
      if (
        this.property.propertyName == ""

      ) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please fill all fields",
        });
        return;
      } else {
       this.property.token = this.$getToken();
          this.$startLoader();
          this.$store
            .dispatch("ADD_SCHOOL_PROPERTY", this.property)
            .then((response) => {
              if (response.data.status) {
                this.$notify({
                  title: "Success",
                  type: "success",
                  text: response.data.data.message,
                });
                this.$stopLoader();
              }
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                type: "error",
                text: err.response.data.error,
              });
              this.$stopLoader();
            });
        }
      },
      
    getSchools() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SCHOOLS", { token: this.$getToken() })
        .then((res) => {
          this.schools = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$userLogout();
          }
        });
    },

    getProperties() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROPERTIES_CHECK", { token: this.$getToken() })
        .then((res) => {
          this.properties = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$userLogout();
          }
        });
    },
    },
    mounted() {
      this.getSchools();
      this.getProperties();
    }
  }

   

  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/main.scss";
  .page-content {
    width: 100%;
    display: flex;
    justify-content: center;
    form {
      width: 100%;
      max-width: 600px;
      height: max-content;
      padding: 4rem 5rem;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      position: relative;
  
      .form-header {
        margin-bottom: 20px;
        .main-head {
          display: block;
          text-align: center;
          font-size: 20px;
          margin-bottom: 10px;
          span {
            color: $blue;
            font-weight: 600;
          }
        }
       
      }
      .form-body {
        .form-group {
          margin-bottom: 20px;
          position: relative;
          label {
            display: block;
            margin-bottom: 5px;
            position: absolute;
            top: 5px;
            left: 10px;
            font-size: 13px;
          }
          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
          }
          .submit {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
            background: $blue;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              background: darken($blue, 10%);
            }
          }

          .submit-2 {
            width: 100%;
            padding: 10px;
            border: 1px solid #3B4874;
            border-radius: 5px;
            outline: none;
            background: #fff;
            color: #3B4874;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              background: darken($blue, 10%);
              color:#fff;
            }
          }
        }
      }

    }
  }
  </style>