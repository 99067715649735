<template>
<div class="container mx-auto p-4">
    <div class="flex flex-col md:flex-row justify-between mb-4">
      <button class="add-btn mb-2 md:mb-0" @click="openPopup">Add Staff Member</button>

      <select
        v-model="activeSchool"
        @change="getStaff"
        class="w-full md:w-auto border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      >
        <option value="">Select school</option>
        <option :value="school.id" v-for="school in schools" :key="school.id">{{ school.schoolName }}</option>
      </select>

      <model
        :modelTitle="'Add Staff Member'"
        :largeModel="true"
        v-show="popupOpen"
        @closeModel="closePopup"
      >
        <AddStaff @closeModel="closePopup" @StaffSaved="getStaff" />
      </model>
    </div>

    <div class="overflow-x-auto">

    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Name
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Nationality
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Gender
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            High degree
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Department
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Contacts
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>

      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-if="staffs.length > 0"
        v-for="(staff, index) in staffs" :key="index" 
        >

          <td class="px-6 py-4 whitespace-nowrap">{{ staff.userName.names }}</td>
          <td class="px-6 py-4 whitespace-nowrap">{{ staff.userName.nationalityName.countryName }}</td>
          <td class="px-6 py-4 whitespace-nowrap">{{ staff.userName.gender }}</td>
          <td v-if="staff.highestDegree " class="px-6 py-4 whitespace-nowrap">
          {{ staff.highestDegree.degreeName }}</td>
          <td v-if="staff.departement" class="px-6 py-4 whitespace-nowrap">
            {{ staff.departement.name || ""}}
          </td>
          <td v-else class="px-6 py-4 whitespace-nowrap">
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <p>
              {{ staff.userName.telephone }} {{ staff.userName.emailAddress }}
            </p>
          </td>
          <td class="flex items-center space-x-2 mart">
            <router-link :to="{name:'StaffProfile',params: { id: staff.id } }" class="a" ><img src="/assets/images/profile-2.svg" alt="" /></router-link>
            <a href="#"><img src="/assets/images/edit.svg" alt="" /></a>
            <a href="#"><img src="/assets/images/delete.svg" alt="" /></a>
          </td>
         

        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>
  
  <script>
import AddStaff from "./AddStaff.vue";

export default {
  data() {
    return {
      popupOpen: false,
      activeSchool: "",
      staffs:[],
      schools:[],

    };
  },

  components:{AddStaff},

  methods: {
    openPopup() {
      this.popupOpen = true;
    },
    closePopup() {
      this.popupOpen = false;
    },

    getSchools() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SCHOOLS", { token: this.$getToken() })
        .then((res) => {
          this.schools = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.res.status == 401) {
            this.$userLogout();
          }
        });
    },
    getStaff() {
  const schoolId = this.activeSchool;
  if(this.activeSchool==null || this.activeSchool=="" || this.activeSchool==undefined){
    this.activeSchool == this.schools[0].id;
    this.getStaff();
  }else{
  this.$startLoader();
  try {
    this.$store.dispatch("GET_SCHOOL_STAFF", { 
      token: this.$getToken(),
      school: schoolId,
    })
    .then((res) => {
      console.log(res)
      this.staffs = res.data.data.data;
      this.$stopLoader();
      
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        this.$userLogout();
      } else {
        console.error(err);
      }
    });
  } catch (error) {
    console.error(error);
  }
    }

  },
},
  mounted(){
    this.getSchools()
  }
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/scss/main.scss";

.plan {
  width: 120px;
  padding: 10px;
  margin-left: 500px;
  border: 1px solid $blue;
  border-radius: 5px;
  outline: none;
  background: white;
  color: $blue;
  font-weight: 600;
}

.mart {
  margin-top: 20px;
}

.add-btn {
  width: 160px;
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  outline: none;
  background: $blue;
  color: white;
  font-weight: 600;
}

.container {
  width: 100%;
}

.add-btn {
  width: 100%;
  max-width: 160px; /* Set a max width for the button */
}

select {
  max-width: 300px; /* Set a max width for the select element */
}
</style>
  