<template>
  <div class="school-profile">
    <div class="top-row profile-section row">
      <div class="left">
        <label for="school name" class="school-name">{{
          schoolData.schoolName
        }}</label>
        <img
          :src="'/assets/images/' + schoolData.schoolLogo"
          alt=""
          v-if="schoolData.schoolLogo"
        />
        <img src="/assets/images/king.jpg" alt="" v-else />
      </div>
      <div class="right">
        <label for="school name" class="school-name">Students</label>
        <div class="chart">
          <apexchart
            type="pie"
            width="450"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
    <div class="profile-section">
      <p class="section-title">General</p>
      <div class="info">
        <div>
          <strong>Representative:</strong> {{ schoolData.representative }}
        </div>
        <div>
          <strong>Levels:</strong>
          <ul>
            <li v-for="(level, index) in schoolData.schoollevels" :key="index">
              {{ level.levelName }}
            </li>
          </ul>
        </div>
        <div><strong>Category:</strong> {{ schoolData.schoolCategory }}</div>
        <div><strong>Gender:</strong> {{ schoolData.schoolAccomodation }}</div>
      </div>
    </div>

    <div class="profile-section">
      <p class="section-title">Contacts</p>
      <div class="info">
        <div><strong>Email:</strong> {{ schoolData.schoolEmail }}</div>
        <div><strong>Phone:</strong> {{ schoolData.schoolTelephone }}</div>
        <div><strong>Website:</strong> {{ schoolData.schoolWebsite }}</div>
        <div
          v-if="
            schoolData.cell ||
            schoolData.sector ||
            schoolData.district ||
            schoolData.provinceName
          "
        >
          <strong>Location:</strong>
          {{ schoolData.cell.cellName }}, {{ schoolData.sector.sectorName }},
          {{ schoolData.district.districtName }}
          {{ schoolData.provinceName.provinceName }}
        </div>
      </div>
    </div>

    <div class="profile-section row">
      <p class="section-title">Properties</p>
      <div class="info">
        <strong>School Assets:</strong>
        <ul class="property-list">
          <div class="left">
            <li
              v-for="(value, property) in schoolData.schoolProperties"
              :key="property"
            >
              <div v-if="property == 'totalClasses'">
                Classes :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'totalTeachers'">
                Teachers :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'electricityConnector'">
                Electricity connector :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'waterConnector'">
                Water connector :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'waterFilters'">
                Water filters :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'hasInternet'">
                Internet :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'solarSystem'">
                Solar system :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
            </li>
          </div>
          <div class="mid-1">
            <li
              v-for="(value, property) in schoolData.schoolProperties"
              :key="property"
            >
              <div v-if="property == 'totalDesktops'">
                Desktops :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'totalLaptops'">
                Laptops :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'totalPrinters'">
                Printers :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'flatScreens'">
                Flat screens :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'speakers'">
                Speakers :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'microphones'">
                Microphones :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'mixers'">
                Mixers :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
            </li>
          </div>
          <div class="mid-2">
            <li
              v-for="(value, property) in schoolData.schoolProperties"
              :key="property"
            >
              <div v-if="property == 'area'">
                Land area size :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'agriculturalLand'">
                Agricultural land :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'cars'">
                Cars :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'motocycle'">
                Motocycles :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'bicycles'">
                Bicycles :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
            </li>
          </div>
          <div class="right">
            <li
              v-for="(value, property) in schoolData.schoolProperties"
              :key="property"
            >
              <div v-if="property == 'Dustbins'">
                Dustbins :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'fireInsurance'">
                Fire insurance :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'fireExtinguishers'">
                Fire extinguishers :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
              <div v-if="property == 'lightningProtector'">
                Lightning protector :
                <span v-if="value != 0">{{ value }}</span>
                <span v-else style="color: dimgray; font-size: 13px"
                  >Not available</span
                >
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>

    <div class="profile-section" v-if="schoolData.representative">
      <p class="section-title">Representative</p>
      <div class="info">
        <div>
          <strong>Representative:</strong> {{ schoolData.representative }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schoolData: [],
      chart: null,
      options: {
        animationEnabled: true,
        title: {
          text: "Students",
        },
        data: [
          {
            type: "pie",
            dataPoints: [
              {
                label: "Male Students",
                y: 10,
              },
              { label: "Female students", y: 15 },
            ],
            indexLabel: "{label} (#percent%)",
          },
        ],
      },

      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        colors: ["#1c62b1", "#934474"],
        labels: ["Male students", "Female students"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    getSchoolProfile() {
      const schoolId = this.$route.params.id;
      this.$startLoader();
      this.$store
        .dispatch("GET_SCHOOL_BY_ID", {
          token: this.$getToken(),
          schoolId,
        })
        .then((response) => {
          this.schoolData = response.data.data.data;
          this.series = [
            this.schoolData.schoolProperties.maleStudents,
            this.schoolData.schoolProperties.femaleStudents,
          ];
          var k = JSON.parse(
            JSON.stringify(
              this.schoolData.schoolProperties,
              [
                "totalClasses",
                "totalTeachers",
                "electricityConnector",
                "waterConnector",
                "waterFilters",
                "hasInternet",
                "solarSystem",
                "totalDesktops",
                "totalLaptops",
                "totalPrinters",
                "flatScreens",
                "speakers",
                "microphones",
                "mixers",
                "area",
                "agriculturalLand",
                "cars",
                "motocycle",
                "bicycles",
                "Dustbins",
                "fireInsurance",
                "fireExtinguishers",
                "lightningProtector",
              ],
              4
            )
          );
          this.schoolData.schoolProperties = k;

          this.$stopLoader();
        })
        .catch((err) => {
          console.error(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },

    $camelToSnake(str) {
      return str
        .replace(/[\w]([A-Z])/g, function (m) {
          return m[0] + " " + m[1];
        })
        .toLowerCase();
    },
  },
  mounted() {
    this.getSchoolProfile();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.school-profile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem;
  margin: 0 5rem;
  align-self: center;
  column-gap: 2rem;
}
.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 !important;
  .left {
    width: 50%;
    background: $blue;
    .school-name {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0.7rem 0;
      display: block;
      text-align: center;
      color: #fff;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .right {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: $blue;
    height: 100%;
    .school-name {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0.7rem 0;
      display: block;
      text-align: center;
      color: white;
    }
    .chart {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
    }
  }
}

.property-list {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.property-list li {
  // text-transform: capitalize;
  margin-bottom: 0;
}

.profile-section {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &.row {
    grid-column: 1 / 3;
  }
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.info {
  padding-left: 1rem;
}

ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

ul li {
  margin-bottom: 0.25rem;
}

strong {
  font-weight: bold;
}
</style>
