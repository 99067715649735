import { API_URL } from '../variables';
import axios from 'axios'
const GET_SCHOOL_STAFF_URL = API_URL+'/staffs/all';
const GET_STAFF_ROLES_URL = API_URL + '/staffs/roles';
const ADD_STAFF_URL = API_URL + '/staffs';
const GET_STAFF_BY_ID_URL= API_URL +'/staffs/'
const staff = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_STAFF_ROLES']: ({ commit }, payload) => {
            return axios({
                url: GET_STAFF_ROLES_URL,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['ADD_STAFF']: ({ commit }, payload) => {
            return axios({
                url: ADD_STAFF_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_STAFF_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: GET_STAFF_BY_ID_URL + payload.staffId,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }

            })
        },

        ['GET_SCHOOL_STAFF']: ({ commit }, payload) => {
            return axios({
                url: GET_SCHOOL_STAFF_URL,
                method: "POST",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }

            })
        },

        
    },
    modules: {},
}
export default staff;