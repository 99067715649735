<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    $txt(txt) {
      const lang = localStorage.getItem("lang");
      if (!lang) {
        localStorage.setItem("lang", "en");
        const lang = "en";
      }
      if (lang === "en") {
        let newText = this.$store.state.translations.find(
          (translation) => translation.en == txt
        );
        if (!newText) {
          return txt;
        } else {
          return newText.en;
        }
      }
      if (lang === "kny") {
        let newText = this.$store.state.translations.find(
          (translation) => translation.en == txt
        );
        if (!newText) {
          return txt;
        } else {
          return newText.kny;
        }
      }
    },
    $changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    $getCountries() {
      this.$store.dispatch("GET_COUNTRIES").then((res) => {
        this.$store.state.countries = res.data.data.data;
      });
    },
    $userLogin(user, token) {
      localStorage.setItem("i_user", JSON.stringify(user));
      localStorage.setItem("i_token", token);
      this.$router.push({ name: "allSchools" });
    },
    $userLogout() {
      let userToken = this.$getToken();
      localStorage.removeItem("i_user");
      localStorage.removeItem("i_token");
      this.$store
        .dispatch("LOGOUT_USER", { token: userToken })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.data.message,
            });
            this.$router.push({ name: "Login" });
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: res.data.error,
            });
          }
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    $getUser() {
      return JSON.parse(localStorage.getItem("i_user"));
    },
    $getToken() {
      return localStorage.getItem("i_token");
    },
    $isSuperAdmin() {
      let user = this.$getUser();
      if (user.userRole.roleName == "Super Administrator") {
        return true;
      } else {
        return false;
      }
    },
    $isAdmin() {
      let user = this.$getUser();
      if (user.userRole.roleName == "Administrator") {
        return true;
      } else {
        return false;
      }
    },
    onCancel() {
      let loader = useLoading();
      loader.hide();
    },
    $startLoader() {
      this.$store.state.isLoading = true;
    },
    $stopLoader() {
      this.$store.state.isLoading = false;
    },
    $urlCompatible(url) {
      if (!url) {
        return;
      }
      return url.replace(/\s/g, "-");
    },
    $getDomains() {
      this.$store
        .dispatch("GET_DOMAINS", { token: this.$getToken() })
        .then((res) => {
          return res.data.data.data;
        });
    },
    $getLevels() {
      this.$store
        .dispatch("GET_LEVELS", { token: this.$getToken() })
        .then((res) => {
          return res.data.data.data;
        });
    },
    $getDegrees() {
      this.$store
        .dispatch("GET_DEGREES", { token: this.$getToken() })
        .then((res) => {
          return res.data.data.data;
        });
    },
    $toggleSANav() {
      this.$store.state.showSANav = !this.$store.state.showSANav;
      localStorage.setItem("showSANav", this.$store.state.showSANav);
    },
  },
  mounted() {
    let showSANav = localStorage.getItem("showSANav");
    if (showSANav != undefined && showSANav != null) {
      // convert to boolean
      if (showSANav == "true") {
        this.$store.state.showSANav = true;
      } else {
        this.$store.state.showSANav = false;
      }
    }
  },
};
</script>