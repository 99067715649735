import { API_URL } from '../variables';
import axios from 'axios'
const GET_ACADEMIC_YEARS_URL = API_URL + '/schools/academicyears';
const ADD_ACADEMIC_YEAR_URL = API_URL + '/schools/academicyears';
const EDIT_ACADEMIC_YEAR_URL = API_URL + '/academicyears/edit-academicyear';
const DELETE_ACADEMIC_YEAR_URL = API_URL + '/academicyears/delete-academicyear';


const academicYears = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_ACADEMIC_YEARS']: ({ commit }, payload) => {
            return axios({
                url: GET_ACADEMIC_YEARS_URL,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['ADD_ACADEMIC_YEAR']: ({ commit }, payload) => {
            return axios({
                url: ADD_ACADEMIC_YEAR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['EDIT_ACADEMIC_YEAR']: ({ commit }, payload) => {
            return axios({
                url: EDIT_ACADEMIC_YEAR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_ACADEMIC_YEAR']: ({ commit }, payload) => {
            return axios({
                url: DELETE_ACADEMIC_YEAR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default academicYears;