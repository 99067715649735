<template>
    <div v-if="isOpen" class="popup">
      <div class="popup-content">
        <slot></slot>
        <button @click="closePopup">Close Popup</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
    methods: {
      closePopup() {
        this.$emit('closePopup');
      },
    },
  };
  </script>
  
  <style scoped>
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
  }
  </style>
  