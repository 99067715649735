<template>
  <div class="AddSchool">
    <edu-navbar />
    <router-view />
  </div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {
    if (
      !this.$getUser() ||
      !this.$getToken() ||
      this.$getUser() == "undefined" ||
      this.$getToken() == "undefined"
    ) {
      this.$router.push({ name: "Login" });
      this.$notify({
        title: "Error",
        type: "error",
        text: "Please login to continue",
      });
    }
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.AddSchool {
  .page-content {
    width: 100%;
    display: flex;
    justify-content: center;
    form {
      width: 100%;
      max-width: 70%;
      min-height: 500px;
      padding: 2rem 5rem;
      border-radius: 5px;
      // box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      position: relative;

      .form-header {
        margin-bottom: 20px;
        .progress-bar {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 2rem;
          .step {
            width: 100px;
            height: 10px;
            border-radius: 5px;
            background: #ddd;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
            font-weight: 600;
            color: #fff;
            &.active {
              background: $blue;
            }
          }
          .progress {
            width: 100%;
            background: #ddd;
            .bar {
              width: 0%;
              height: 5px;
              background: #ddd;
              position: relative;
              background: $blue;
              &.active {
                width: 100%;
                transition: all 0.1s ease-in-out;
              }
            }
          }
        }
        .main-head {
          display: block;
          text-align: center;
          font-size: 20px;
          margin-bottom: 10px;
          span {
            color: $blue;
            font-weight: 600;
          }
        }
        .dont {
          display: block;
          text-align: center;
          span,
          a {
            color: $blue;
            font-weight: 600;
          }
        }
      }
      .form-body {
        .form-group {
          margin-bottom: 20px;
          position: relative;
          label {
            display: block;
            margin-bottom: 5px;
            position: absolute;
            top: 5px;
            left: 10px;
            font-size: 13px;
          }
          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
          }
          select {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
          }
          .submit {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
            background: $blue;
            color: #fff;
            font-weight: 600;
          }
          .prev {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
            background: #ddd;
            color: #000;
            font-weight: 600;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        .group-2 {
          display: flex;
          column-gap: 2rem;
        }
        .group-3 {
          display: flex;
          column-gap: 2rem;
        }
        .group-4 {
          display: flex;
          column-gap: 2rem;
        }
      }
      .form-footer {
        position: absolute;
        bottom: 20px;
        width: calc(100% - 10rem);
        h4 {
          text-align: center;
        }
      }
    }
  }
}
</style>