<template>
  <div class="Education">
    <div class="page-body">
      <div class="filter-schools">
        <div class="filter-left on-lg">
          <!-- <div class="most-liked">
            <input type="checkbox" name="most-liked" id="" />
            <label for="">{{ $txt("Most&nbsp;liked") }}</label>
          </div> -->
          <select name="province" id="">
            <option value="">{{ $txt("Province") }}</option>
            <option value="">{{ $txt("Kigali City") }}</option>
            <option value="">{{ $txt("North") }}</option>
            <option value="">{{ $txt("South") }}</option>
            <option value="">{{ $txt("East") }}</option>
            <option value="">{{ $txt("West") }}</option>
          </select>
          <select name="district" id="">
            <option value="">{{ $txt("District") }}</option>
          </select>
          <select name="sector" id="">
            <option value="">{{ $txt("Sector") }}</option>
          </select>
          <select name="cell" id="">
            <option value="">{{ $txt("Cell") }}</option>
          </select>
        </div>
        <div class="filter-right">
          <form class="search">
            <input type="text" :placeholder="$txt('Search')" />
            <i class="fa fa-search"></i>
          </form>
        </div>
      </div>
      <div class="schools-list">
        <div class="school-item" v-for="school in schools" :key="school.id">
          <!-- <div class="school-item-left">
            <img src="/assets/images/king.jpg" alt="" />
          </div> -->
          <div class="school-item-right">
            <div class="left">
              <h3 class="school-name">{{ school.schoolName }}</h3>
              <p>{{ school.schoolEmail }}</p>
              <p>{{ school.schoolTelephone }}</p>
              <p>{{ school.schoolWebsite }}</p>
              <p>
                <span v-for="level in school.schoollevels" :key="level.levelId"
                  >{{ level.levelName }}
                </span>
              </p>
            </div>
            <div class="director">
              <p>Director/Principal/HM:</p>
              <label>{{ school.schoolRepresenter }}</label>
              <p class="message">
                {{ school.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Education",
  data() {
    return {
      schools: [],
    };
  },
  methods: {
    getSchools() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_SCHOOLS")
        .then((res) => {
          if (res.data.status) {
            this.schools = res.data.data.data;
            this.$stopLoader();
          } else {
            this.$stopLoader();
          }
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getSchools();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Education {
  padding: 2rem 5rem;
  .page-body {
    margin-top: 4rem;
    .filter-schools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filter-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: fit-content;
        .most-liked {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          input {
            margin-right: 10px;
            font-size: 12px;
          }
          label {
            display: block;
            font-size: 12px;
          }
        }
        select {
          width: 100px;
          height: 40px;
          border: none;
          border-bottom: 1px solid #ccc;
          padding: 0 10px;
          font-size: 12px;
          margin-left: 10px;
        }
      }
      .filter-right {
        width: 300px;
        .search {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          position: relative;
          input {
            width: 100%;
            height: 40px;
            border-radius: 5px;
            border: 1px solid #ccc;
            padding: 0 10px;
            padding-right: 40px;
          }
          i {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute !important;
            right: 10px;
          }
        }
      }
    }
    .schools-list {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      .school-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding: 1rem 0;
        border-bottom: 3px solid #9b9b9b;
        box-shadow: 0 0 5px #ccc;
        .school-item-left {
          width: 20%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .school-item-right {
          width: 80%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            width: 60%;
            margin-left: 10px;
            .school-name {
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 10px;
              color: $blue;
            }
            p {
              font-size: 13px;
              margin-bottom: 5px;
            }
          }
          .director {
            width: 40%;
            p {
              font-size: 13px;
              margin-bottom: 5px;
              font-weight: 600;
            }
            label {
              font-size: 13px;
              margin-bottom: 5px;
              font-weight: bold;
              color: $blue;
            }
            .message {
              font-size: 12px;
              margin-bottom: 5px;
              color: #1f1f1f;
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
              overflow: hidden;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
</style>
  