<template>
  <div>
    <form class="" @submit="saveStaff()">
      <div class="mb-4 flex flex-wrap">
        <div class="w-1/2 pr-2">
          <label class="block text-gray-700 text-sm mb-2" for="names">
            {{ $txt("School") }}
          </label>
          <select
            name=""
            id=""
            v-model="staff.school"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">{{ $txt("Select school") }}</option>
            <option :value="school.id" v-for="school in schools" :key="school.id">{{ school.schoolName }}</option>
          </select>
        </div>
        <div class="w-1/2 pl-2">
          <label class="block text-gray-700 text-sm mb-2" for="names">
            {{ $txt("Names") }}
          </label>
          <input
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="names"
            type="text"
            placeholder="Full Name"
            v-model="staff.names"
          />
        </div>
      </div>

      <div class="mb-4 flex flex-wrap">
        <div class="w-1/3 pr-2">
          <label class="block text-gray-700 text-sm mb-2" for="phone">
            {{ $txt("Phone Number") }}
          </label>
          <input
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="phone"
            type="text"
            placeholder="Phone Number"
            v-model="staff.telephone"
          />
        </div>
        <div class="w-1/3 px-2">
          <label class="block text-gray-700 text-sm mb-2" for="email">
            {{ $txt("Email") }}
          </label>
          <input
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email Address"
            v-model="staff.email"
          />
        </div>
        <div class="w-1/3 pl-2">
          <label class="block text-gray-700 text-sm mb-2" for="role">
            {{ $txt("Role") }}
          </label>
          <select
            name=""
            id=""
            v-model="staff.role"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">{{ $txt("Select role") }}</option>
            <option :value="role.roleName" v-for="role in roles" :key="role.id">
              {{ role.roleName }}
            </option>
          </select>
        </div>
      </div>

      <div class="mb-4 flex flex-wrap">
        <div class="w-1/3 pl-3">
          <label class="block text-gray-700 text-sm mb-2" for="role">
            {{ $txt("Gender") }}
          </label>
          <select
            name=""
            id=""
            v-model="staff.gender"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">{{ $txt("Gender") }}</option>
            <option>Male</option>
            <option>Female</option>
          </select>
        </div>
        <div class="w-1/3 pl-3">
          <label class="block text-gray-700 text-sm mb-2" for="names">
            {{ $txt("Nationality") }}
          </label>
          <select
            name=""
            id=""
            v-model="staff.nationality"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">{{ $txt("Select nationality") }}</option>
            <option
              :value="country.countryId"
              v-for="country in countries"
              :key="country.countryId"
            >
              {{ country.countryName }}
            </option>
          </select>
        </div>

        <div class="w-1/3 pl-3">
          <label class="block text-gray-700 text-sm mb-2" for="names">
            {{ $txt("Department") }}
          </label>
          <select
            name=""
            id=""
            v-model="staff.depart_id"
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">{{ $txt("Select department") }}</option>
            <option
              :value="department.id"
              v-for="department in departments"
              :key="department.id"
            >
              {{ department.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="mb-4 flex flex-wrap">
        <div class="w-1/3 pr-2">
          <label class="block text-gray-700 text-sm mb-2" for="educationDomain">
            {{ $txt("Domain of Education") }}
          </label>
          <input
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="educationDomain"
            type="text"
            placeholder="Education Domain"
            v-model="staff.domain_education"
          />
        </div>
        <div class="w-1/3 pl-2">
          <label class="block text-gray-700 text-sm mb-2" for="educationLevel">
            {{ $txt("Highest Education Level") }}
          </label>
          <select
          name=""
          id=""
        v-model="staff.degree_id"
        class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
        <option value="">{{ $txt("Select degree") }}</option>
        <option
          :value="degree.degreeId"
          v-for="degree in degrees"
          :key="degree.degreeId"
        >
          {{ degree.degreeName }}
        </option>
      </select>

        </div>
        <div class="w-1/3 pl-2">
          <label class="block text-gray-700 text-sm mb-2" for="educationLevel">
            {{ $txt("National ID / Passport") }}
          </label>
          <input
            class="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="educationLevel"
            type="text"
            placeholder="National ID / Passport"
            v-model="staff.idnumber"
          />
        </div>
      </div>

      <!-- Submit Button -->
      <div class="flex items-center justify-center mt-4">
        <button class="submit" type="button" @click="saveStaff()">
          {{ $txt("Add Staff") }}
        </button>
      </div>
    </form>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      selectedGender: "male",
      staff: {
        school: "",
        email: "",
        names: "",
        telephone: "",
        idnumber: "",
        nationality: "",
        gender: "",
        role: "",
        domain_education: "",
        degree_id: "",
        fallback_url: "",
        depart_id:"",
      },
      schools: [],
      countries: [],
      roles: null,
      degrees:[],
      departments:[],

    };
  },
  methods: {
    closeModel() {
      this.$emit("closeModel");
    },
    getSchools() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SCHOOLS", { token: this.$getToken() })
        .then((res) => {
          this.schools = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$userLogout();
          }
        });
    },

    getDepartments() {
      this.$startLoader();
      this.$store
        .dispatch("GET_DEPARTMENTS", { token: this.$getToken() })
        .then((res) => {
          this.departments = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$userLogout();
          }
        });
    },

    getDegrees() {
      this.$startLoader();
      this.$store
        .dispatch("GET_DEGREES", { token: this.$getToken() })
        .then((res) => {
          this.degrees = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$userLogout();
          }
        });
    },
    getRoles() {
      this.$startLoader();
      this.$store
        .dispatch("GET_STAFF_ROLES", { token: this.$getToken() })
        .then((res) => {
          this.roles = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$userLogout();
          }
        });
    },
    saveStaff() {
      this.staff.token = this.$getToken();
      this.staff.fallback_url = process.env.VUE_APP_REGISTER_CALLBACK;

      this.$startLoader();
      this.$store
        .dispatch("ADD_STAFF", {
          token: this.$getToken(),
          data: this.staff,
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Staff added successfully",
            });
            this.$stopLoader();
            this.closeModel();
            this.$emit('StaffSaved')
          }
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.message,
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getSchools();
    this.$store.dispatch("GET_COUNTRIES").then((res) => {
      this.countries = res.data.data.data;
    });
    this.getRoles();
    this.getDegrees();
    this.getDepartments();
    
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";

form {
  width: 100%;
  padding: 2rem 3rem;
  border-radius: 5px;
  position: relative;
}

.submit {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  background: $blue;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: darken($blue, 10%);
  }
}
</style>
  